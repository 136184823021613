// import '@fontsource/fira-sans/300.css';
import '@fontsource/fira-sans/400.css';
import '@fontsource/fira-sans/700.css';
import { LocationProvider } from '@reach/router';
import React from 'react';

import './src/styles/global.scss';

import { CartProvider } from './src/hooks/useCartContext';
import { AuthUserProvider } from './src/utilities/AuthUser';
import Loader from './src/utilities/Loader';

export const wrapRootElement = ({ element }) => (
  <LocationProvider>
    <AuthUserProvider>
      <CartProvider>{element}</CartProvider>
    </AuthUserProvider>
  </LocationProvider>
);

export const wrapPageElement = ({ element }) => {
  return <Loader>{element}</Loader>;
};

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
};

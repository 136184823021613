import axios from 'axios';

import { getToken } from '../utilities/storage/authStorage.js';

export const API_URL = process.env.GATSBY_API_URL;

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  function (config) {
    const token = getToken();
    if (!token) {
      if (config.headers.common && config.headers.common['Authorization'])
        delete config.headers.common['Authorization'];
    } else {
      config.headers.common['Authorization'] = `Bearer ${getToken()}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
